import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import PageBanner from '../components/PageBanner';
import PostLink from "../components/PostLink";
import pic08 from '../assets/images/pic08.jpg';

var BlogPage = function BlogPage(_ref) {
  var edges = _ref.data.allMarkdownRemark.edges;
  var Posts = edges.filter(function (edge) {
    return !!edge.node.frontmatter.date;
  }) // You can filter your posts based on some criteria
  .map(function (edge) {
    return React.createElement(PostLink, {
      key: edge.node.id,
      post: edge.node
    });
  });
  return React.createElement(Layout, null, React.createElement(Helmet, null, React.createElement("title", null, "Hospitality Tribe - The Blog"), React.createElement("meta", {
    name: "description",
    content: "Landing Page"
  })), React.createElement(PageBanner, {
    title: "The Blog"
  }), React.createElement("div", {
    id: "main"
  }, React.createElement("section", {
    id: "one"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h2", null, "Blog Posts")), Posts))));
};

export default BlogPage;
export var pageQuery = "1155256817";