import React from "react";
import { Link } from "gatsby";

var PostLink = function PostLink(_ref) {
  var post = _ref.post;
  return React.createElement("div", null, React.createElement(Link, {
    to: post.frontmatter.path
  }, post.frontmatter.title, " (", post.frontmatter.date, ")"));
};

export default PostLink;