import React from 'react';

var PageBanner = function PageBanner(props) {
  return React.createElement("section", {
    id: "banner",
    className: "style1"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h1", null, props.title)), React.createElement("div", {
    className: "content"
  })));
};

export default PageBanner;